/** @jsxImportSource @emotion/react */
import { type SerializedStyles, css } from "@emotion/react";
import NextImage, {
    type ImageProps as NextImageProps,
    type StaticImageData,
} from "next/image";

import { buildCustomAspectRatio } from "@/util/data_util";

type ImageProps = Omit<NextImageProps, "className" | "src"> & {
    className?: SerializedStyles;
    src: StaticImageData;
};

const baseStyles = css({
    height: "auto",
    width: "100%",
});

export const Image = (props: ImageProps) => {
    const { className, src, ...rest } = props;
    const aspectRatio = buildCustomAspectRatio(src.width, src.height);
    return (
        <NextImage
            {...rest}
            css={css(baseStyles, { aspectRatio }, className)}
            src={src}
        />
    );
};
