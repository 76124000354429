/** @jsxImportSource @emotion/react */
import BackgroundHeadshot1 from "@/img/homepage/testimonial-mosaic/Background_Headshot-1.png";
import BackgroundHeadshot2 from "@/img/homepage/testimonial-mosaic/Background_Headshot-2.png";
import BackgroundHeadshot3 from "@/img/homepage/testimonial-mosaic/Background_Headshot-3.png";
import BackgroundHeadshot4 from "@/img/homepage/testimonial-mosaic/Background_Headshot-4.png";
import BackgroundHeadshot5 from "@/img/homepage/testimonial-mosaic/Background_Headshot-5.png";
import BrowserLogo from "@/img/homepage/testimonial-mosaic/Browser_Logo.svg";
import LaunchDarklyLogo from "@/img/homepage/testimonial-mosaic/LaunchDarkly_Logo.svg";
import LogoPlaceholder from "@/img/homepage/testimonial-mosaic/Logo_Placeholder.svg";
import NotionLogo from "@/img/homepage/testimonial-mosaic/Notion_Logo.svg";
import Portrait1 from "@/img/homepage/testimonial-mosaic/Portrait-1.png";
import Portrait2 from "@/img/homepage/testimonial-mosaic/Portrait-2.png";
import Portrait3 from "@/img/homepage/testimonial-mosaic/Portrait-3.png";
import Portrait4 from "@/img/homepage/testimonial-mosaic/Portrait-4.png";
import Portrait5 from "@/img/homepage/testimonial-mosaic/Portrait-5.png";
import RampLogo from "@/img/homepage/testimonial-mosaic/Ramp_Logo.svg";
import { css } from "@emotion/react";
import { useMemo } from "react";

import { StrapiMosaic } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Image } from "@/ui/atoms/local_image";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

import { ClassicTestimonial } from "./classic_testimonial";

enum LayerIndex {
    backgroundLogos,
    backgroundHeadshots,
    foregroundHeadshots,
    foregroundLogos,
    testimonial,
}

interface TestimonialMosaicProps extends StrapiMosaic {}

export function TestimonialMosaic(props: TestimonialMosaicProps) {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const outerContainerStyles = css({
        overflowX: "clip",
    });

    const containerStyles = css(
        {
            alignItems: "center",
            display: "grid",
            position: "relative",
        },
        buildStylesByBreakpoint("height", {
            extraSmall: convertToRem(640),
            small: convertToRem(760),
            medium: convertToRem(768),
            large: convertToRem(982),
            extraLarge: convertToRem(1024),
        }),
    );

    const testimonailStyles = css({
        position: "relative",
        zIndex: LayerIndex.testimonial,
    });

    /**
     * Rendering
     */
    const backgroundLogosLayer = useMemo(() => {
        const layerStyles = css({
            filter: "blur(16px)",
            inset: 0,
            opacity: 0.5,
            position: "absolute",
            zIndex: LayerIndex.backgroundLogos,
        });

        const commonProps = {
            height: 77,
            src: LogoPlaceholder,
            width: 193,
        };

        const width = buildStylesByBreakpoint("width", {
            extraSmall: convertToRem(73),
            small: convertToRem(91),
            medium: convertToRem(129),
            large: convertToRem(167),
            extraLarge: convertToRem(193),
        });

        return (
            <div css={layerStyles}>
                <Image
                    {...commonProps}
                    alt=""
                    className={css(
                        {
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("top", {
                            extraSmall: convertToRem(158),
                            small: convertToRem(162),
                            medium: convertToRem(198),
                            large: convertToRem(289),
                        }),
                        buildStylesByBreakpoint("left", {
                            extraSmall: convertToRem(158),
                            small: convertToRem(197),
                            medium: convertToRem(501),
                            large: convertToRem(370),
                            extraLarge: convertToRem(529),
                        }),
                        width,
                    )}
                />

                <Image
                    {...commonProps}
                    alt=""
                    className={css(
                        {
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("bottom", {
                            extraSmall: convertToRem(139),
                            small: convertToRem(169),
                            medium: convertToRem(346),
                            large: convertToRem(394),
                            extraLarge: convertToRem(388),
                        }),
                        buildStylesByBreakpoint("right", {
                            extraSmall: convertToRem(123),
                            small: convertToRem(140),
                            medium: convertToRem(58),
                            large: convertToRem(226),
                            extraLarge: convertToRem(238),
                        }),
                        width,
                    )}
                />

                <Image
                    {...commonProps}
                    alt=""
                    className={css(
                        {
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("display", {
                            extraSmall: "none",
                            large: "block",
                        }),
                        buildStylesByBreakpoint("bottom", {
                            large: convertToRem(35),
                            extraLarge: convertToRem(33),
                        }),
                        buildStylesByBreakpoint("left", {
                            large: 0,
                            extraLarge: convertToRem(34),
                        }),
                        width,
                    )}
                />
            </div>
        );
    }, []);

    const backgroundHeadshotsLayer = useMemo(() => {
        const layerStyles = css({
            filter: "blur(24px)",
            inset: 0,
            opacity: 0.5,
            position: "absolute",
            zIndex: LayerIndex.backgroundHeadshots,
        });

        return (
            <div css={layerStyles}>
                <Image
                    alt=""
                    className={css(
                        {
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("display", {
                            medium: "none",
                            large: "block",
                        }),
                        buildStylesByBreakpoint("top", {
                            extraSmall: convertToRem(-19),
                            small: convertToRem(-15),
                            large: 0,
                            extraLarge: convertToRem(-8),
                        }),
                        buildStylesByBreakpoint("right", {
                            extraSmall: convertToRem(-24),
                            small: convertToRem(-26),
                            medium: convertToRem(-30),
                            large: convertToRem(2),
                            extraLarge: convertToRem(78),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(81),
                            small: convertToRem(91),
                            large: convertToRem(122),
                            extraLarge: convertToRem(140),
                        }),
                    )}
                    height={420}
                    src={BackgroundHeadshot1}
                    width={420}
                />

                <Image
                    alt=""
                    className={css(
                        {
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("top", {
                            extraSmall: convertToRem(131),
                            small: convertToRem(172),
                            medium: convertToRem(212),
                            large: convertToRem(269),
                            extraLarge: convertToRem(270),
                        }),
                        buildStylesByBreakpoint("left", {
                            extraSmall: convertToRem(-43),
                            small: convertToRem(-23),
                            medium: 0,
                            extraLarge: convertToRem(28),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(89),
                            small: convertToRem(120),
                            medium: convertToRem(126),
                            large: convertToRem(154),
                            extraLarge: convertToRem(240),
                        }),
                    )}
                    height={720}
                    src={BackgroundHeadshot2}
                    width={720}
                />

                <Image
                    alt=""
                    className={css(
                        {
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("bottom", {
                            extraSmall: convertToRem(-27),
                            small: convertToRem(-41),
                            medium: convertToRem(154),
                            large: convertToRem(232),
                            extraLarge: convertToRem(290),
                        }),
                        buildStylesByBreakpoint("right", {
                            extraSmall: convertToRem(69),
                            small: convertToRem(73),
                            medium: convertToRem(294),
                            large: convertToRem(400),
                            extraLarge: convertToRem(502),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(85),
                            small: convertToRem(112),
                            medium: convertToRem(112),
                            large: convertToRem(145),
                            extraLarge: convertToRem(175),
                        }),
                    )}
                    height={525}
                    src={BackgroundHeadshot3}
                    width={525}
                />

                <Image
                    alt=""
                    className={css(
                        {
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("display", {
                            extraSmall: "none",
                            medium: "block",
                        }),
                        buildStylesByBreakpoint("top", {
                            medium: convertToRem(-7),
                            large: convertToRem(-19),
                            extraLarge: convertToRem(-71),
                        }),
                        buildStylesByBreakpoint("right", {
                            medium: convertToRem(321),
                            large: convertToRem(493),
                            extraLarge: convertToRem(620),
                        }),
                        buildStylesByBreakpoint("width", {
                            medium: convertToRem(104),
                            large: convertToRem(148),
                            extraLarge: convertToRem(209),
                        }),
                    )}
                    height={784}
                    src={BackgroundHeadshot4}
                    width={627}
                />

                {/* Use the same image */}
                <Image
                    alt=""
                    className={css(
                        {
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("display", {
                            extraSmall: "none",
                            large: "block",
                        }),
                        buildStylesByBreakpoint("bottom", {
                            large: convertToRem(-63),
                            extraLarge: convertToRem(-138),
                        }),
                        buildStylesByBreakpoint("left", {
                            large: convertToRem(231),
                            extraLarge: convertToRem(355),
                        }),
                        buildStylesByBreakpoint("width", {
                            large: convertToRem(164),
                            extraLarge: convertToRem(209),
                        }),
                    )}
                    height={784}
                    src={BackgroundHeadshot4}
                    width={627}
                />

                <Image
                    alt=""
                    className={css(
                        {
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("display", {
                            extraSmall: "none",
                            large: "block",
                        }),
                        buildStylesByBreakpoint("bottom", {
                            large: convertToRem(-40),
                            extraLarge: convertToRem(-48),
                        }),
                        buildStylesByBreakpoint("right", {
                            large: convertToRem(461),
                            extraLarge: convertToRem(660),
                        }),
                        buildStylesByBreakpoint("width", {
                            large: convertToRem(128),
                            extraLarge: convertToRem(209),
                        }),
                    )}
                    height={784}
                    src={BackgroundHeadshot5}
                    width={627}
                />
            </div>
        );
    }, []);

    const foregroundHeadshotsLayer = useMemo(() => {
        const layerStyles = css({
            inset: 0,
            position: "absolute",
            zIndex: LayerIndex.foregroundHeadshots,
        });

        return (
            <div css={layerStyles}>
                <Image
                    alt=""
                    className={css(
                        {
                            borderRadius: BorderRadiuses.borderSmall,
                            position: "absolute",
                            top: 0,
                        },
                        buildStylesByBreakpoint("left", {
                            extraSmall: convertToRem(4),
                            small: 0,
                            medium: convertToRem(117),
                            large: convertToRem(96),
                            extraLarge: convertToRem(186),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(141),
                            small: convertToRem(160),
                            medium: convertToRem(192),
                            large: convertToRem(240),
                            extraLarge: convertToRem(300),
                        }),
                    )}
                    height={1125}
                    src={Portrait1}
                    width={900}
                />

                <Image
                    alt=""
                    className={css(
                        {
                            borderRadius: BorderRadiuses.borderSmall,
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("top", {
                            extraSmall: convertToRem(45),
                            small: convertToRem(58),
                            medium: convertToRem(43),
                            large: convertToRem(86),
                            extraLarge: convertToRem(70),
                        }),
                        buildStylesByBreakpoint("right", {
                            extraSmall: 0,
                            medium: convertToRem(59),
                            large: convertToRem(247),
                            extraLarge: convertToRem(334),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(117),
                            medium: convertToRem(160),
                            large: convertToRem(192),
                            extraLarge: convertToRem(240),
                        }),
                    )}
                    height={720}
                    src={Portrait2}
                    width={720}
                />

                <Image
                    alt=""
                    className={css(
                        {
                            borderRadius: BorderRadiuses.borderSmall,
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("display", {
                            extraSmall: "none",
                            medium: "block",
                        }),
                        buildStylesByBreakpoint("bottom", {
                            medium: convertToRem(154),
                            large: convertToRem(204),
                            extraLarge: convertToRem(180),
                        }),
                        buildStylesByBreakpoint("left", {
                            medium: convertToRem(-9),
                            large: convertToRem(-101),
                            extraLarge: convertToRem(-86),
                        }),
                        buildStylesByBreakpoint("width", {
                            medium: convertToRem(154),
                            large: convertToRem(192),
                            extraLarge: convertToRem(240),
                        }),
                    )}
                    height={720}
                    src={Portrait3}
                    width={720}
                />

                <Image
                    alt=""
                    className={css(
                        {
                            borderRadius: BorderRadiuses.borderSmall,
                            bottom: 0,
                            position: "absolute",
                        },
                        buildStylesByBreakpoint("left", {
                            extraSmall: convertToRem(4),
                            small: 0,
                            medium: convertToRem(250),
                            large: convertToRem(431),
                            extraLarge: convertToRem(593),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(152),
                            small: convertToRem(185),
                            medium: convertToRem(192),
                            large: convertToRem(240),
                            extraLarge: convertToRem(300),
                        }),
                    )}
                    height={900}
                    src={Portrait4}
                    width={900}
                />

                <Image
                    alt=""
                    className={css(
                        {
                            borderRadius: BorderRadiuses.borderSmall,
                            position: "absolute",
                            right: 0,
                        },
                        buildStylesByBreakpoint("bottom", {
                            extraSmall: convertToRem(42),
                            small: convertToRem(82),
                            medium: convertToRem(116),
                            large: convertToRem(112),
                            extraLarge: convertToRem(72),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(108),
                            medium: convertToRem(154),
                            large: convertToRem(192),
                            extraLarge: convertToRem(240),
                        }),
                    )}
                    height={900}
                    src={Portrait5}
                    width={720}
                />
            </div>
        );
    }, []);

    const foregroundLogosLayer = useMemo(() => {
        const layerStyles = css({
            inset: 0,
            position: "absolute",
            userSelect: "none",
            zIndex: LayerIndex.foregroundLogos,
        });

        const logoContainerStyles = css(
            {
                backdropFilter: "blur(24px)",
                backgroundColor: Colors[theme.backgrounds.backgroundSecondary],
                borderRadius: BorderRadiuses.borderMedium,
                position: "absolute",
            },
            buildStylesByBreakpoint("padding", {
                extraSmall: Spacing["spacing-3"],
                medium: Spacing["spacing-4"],
                extraLarge: Spacing["spacing-5"],
            }),
        );

        const logoStyles = css({
            borderRadius: BorderRadiuses.borderNone,
            opacity: 0.5,
        });

        return (
            <div css={layerStyles}>
                <figure
                    css={css(
                        logoContainerStyles,
                        buildStylesByBreakpoint("top", {
                            extraSmall: convertToRem(28),
                            small: convertToRem(31),
                            medium: convertToRem(79),
                            large: convertToRem(150),
                            extraLarge: convertToRem(132),
                        }),
                        buildStylesByBreakpoint("left", {
                            extraSmall: convertToRem(107),
                            small: convertToRem(139),
                            medium: convertToRem(364),
                            large: convertToRem(551),
                            extraLarge: convertToRem(786),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(64),
                            small: convertToRem(80),
                            medium: convertToRem(80),
                            large: convertToRem(112),
                            extraLarge: convertToRem(128),
                        }),
                    )}
                >
                    <Image
                        alt="The Browser Company Logo"
                        className={logoStyles}
                        height={64}
                        src={BrowserLogo}
                        width={128}
                    />
                </figure>

                <figure
                    css={css(
                        logoContainerStyles,
                        buildStylesByBreakpoint("top", {
                            extraSmall: convertToRem(168),
                            small: convertToRem(200),
                            medium: convertToRem(265),
                            large: convertToRem(379),
                            extraLarge: convertToRem(370),
                        }),
                        buildStylesByBreakpoint("right", {
                            extraSmall: convertToRem(-21),
                            small: convertToRem(-26),
                            medium: convertToRem(20),
                            large: convertToRem(107),
                            extraLarge: convertToRem(182),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(63),
                            small: convertToRem(70),
                            medium: convertToRem(70),
                            large: convertToRem(98),
                            extraLarge: convertToRem(112),
                        }),
                    )}
                >
                    <Image
                        alt="Notion Logo"
                        className={logoStyles}
                        height={32}
                        src={NotionLogo}
                        width={112}
                    />
                </figure>

                <figure
                    css={css(
                        logoContainerStyles,
                        buildStylesByBreakpoint("bottom", {
                            extraSmall: convertToRem(143),
                            small: convertToRem(174),
                            medium: convertToRem(52),
                            large: convertToRem(221),
                            extraLarge: convertToRem(223),
                        }),
                        buildStylesByBreakpoint("left", {
                            extraSmall: convertToRem(-24),
                            small: convertToRem(-20),
                            medium: convertToRem(97),
                            large: convertToRem(197),
                            extraLarge: convertToRem(299),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(58),
                            small: convertToRem(65),
                            medium: convertToRem(65),
                            large: convertToRem(86),
                            extraLarge: convertToRem(101),
                        }),
                    )}
                >
                    <Image
                        alt="Ramp Logo"
                        className={logoStyles}
                        height={28}
                        src={RampLogo}
                        width={101}
                    />
                </figure>

                <figure
                    css={css(
                        logoContainerStyles,
                        buildStylesByBreakpoint("bottom", {
                            extraSmall: convertToRem(23),
                            small: convertToRem(50),
                            medium: convertToRem(46),
                            large: convertToRem(141),
                            extraLarge: convertToRem(123),
                        }),
                        buildStylesByBreakpoint("right", {
                            extraSmall: convertToRem(-47),
                            small: convertToRem(-62),
                            medium: convertToRem(161),
                            large: convertToRem(209),
                            extraLarge: convertToRem(289),
                        }),
                        buildStylesByBreakpoint("width", {
                            extraSmall: convertToRem(104),
                            small: convertToRem(117),
                            medium: convertToRem(117),
                            large: convertToRem(143),
                            extraLarge: convertToRem(157),
                        }),
                    )}
                >
                    <Image
                        alt="Launch Darkly Logo"
                        className={logoStyles}
                        height={24}
                        src={LaunchDarklyLogo}
                        width={157}
                    />
                </figure>
            </div>
        );
    }, [theme.backgrounds.backgroundSecondary]);

    return (
        <GridContainer
            className={containerStyles}
            containerClassName={outerContainerStyles}
            legacyGrid={false}
        >
            {backgroundLogosLayer}

            {backgroundHeadshotsLayer}

            {foregroundHeadshotsLayer}

            {foregroundLogosLayer}

            <GridColumn className={testimonailStyles}>
                <ClassicTestimonial {...props} />
            </GridColumn>
        </GridContainer>
    );
}
