/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import type { StrapiHomePageExperiment } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";

import { Text } from "@/ui/atoms/text";
import { AmbientList } from "@/ui/organisms/ambient_list";
import { BigTextHero } from "@/ui/organisms/big_text_hero";
import { BillboardCarousel } from "@/ui/organisms/billboard_carousel";
import { ClassicTestimonial } from "@/ui/organisms/classic_testimonial";
import { PrivacyList } from "@/ui/organisms/privacy_list";
import { ProductFeature } from "@/ui/organisms/product_feature";
import { SpecSheet } from "@/ui/organisms/spec_sheet";
import { TestimonialMosaic } from "@/ui/organisms/testimonial_mosaic";
import { VideoBlock } from "@/ui/organisms/video_block";

import { buildStylesByBreakpoint } from "@/util/style_util";

export function HomePageTemplate(props: StrapiHomePageExperiment) {
    /**
     * Styles
     */
    const pageStyles = css(
        { display: "flex", flexDirection: "column" },
        buildStylesByBreakpoint("rowGap", spacingSets.DefaultSectionGap),
    );

    const videoBlockStyles = buildStylesByBreakpoint("display", {
        extraSmall: "flex",
        medium: "none",
    });

    /**
     * Elements
     */
    const renderPlaceholderString = (componentName: string, key: string) => {
        return <Text key={key}>{`The future home of ${componentName}`}</Text>;
    };

    const renderPageBody = () => {
        return props.Page_Body.map((pageBodyItem, index) => {
            const key = `home-page::body-item::${pageBodyItem.__component}-${index}`;

            switch (pageBodyItem.__component) {
                case "sections.section-billboard-carousel": {
                    return <BillboardCarousel {...pageBodyItem} key={key} />;
                }
                case "sections.section-product-feature": {
                    return <ProductFeature {...pageBodyItem} key={key} />;
                }
                case "sections.section-spec-sheet": {
                    return <SpecSheet {...pageBodyItem} key={key} />;
                }
                case "sections.section-ambient-list":
                    return <AmbientList {...pageBodyItem} key={key} />;
                case "sections.section-privacy": {
                    return <PrivacyList {...pageBodyItem} key={key} />;
                }
                case "sections.section-testimonial": {
                    return <ClassicTestimonial {...pageBodyItem} key={key} />;
                }
                case "sections.section-mosaic": {
                    return <TestimonialMosaic {...pageBodyItem} key={key} />;
                }

                default: {
                    return renderPlaceholderString(
                        pageBodyItem.__component,
                        key,
                    );
                }
            }
        });
    };

    return (
        <div css={pageStyles}>
            <BigTextHero
                {...props.Hero}
                backgroundMedia={props.Theme?.Hero_Media}
            />

            {/* Inline video for XS + S breakpoints */}
            <VideoBlock className={videoBlockStyles} />

            {renderPageBody()}
        </div>
    );
}
