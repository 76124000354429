/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, useRef } from "react";

import { StrapiMedia } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { ColumnGaps } from "@/tokens/spacing";

import { GridColumn } from "@/ui/atoms/grid_column";
import { Picture } from "@/ui/atoms/picture";
import { RiveAnimation } from "@/ui/atoms/rive_animation";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { OperatingSystemBanner } from "@/ui/molecules/os_banner";

import { useMediaPanningAnimation } from "@/util/animation_hooks/media_animations";
import { buildStylesByBreakpoint } from "@/util/style_util";

interface HeroDemoProps {
    backgroundMedia: StrapiMedia;
    className?: SerializedStyles;
    priority?: boolean;
    src: string;
}

export const HeroDemo: FunctionComponent<HeroDemoProps> = ({
    priority = false,
    ...props
}) => {
    /**
     * Refs
     */
    const containerRef = useRef<HTMLElement>(null);
    const demoContainerRef = useRef<HTMLElement>(null);

    /**
     * Animations
     */
    useMediaPanningAnimation(containerRef, demoContainerRef);

    /**
     * Styles
     */
    const demoContainerStyles = css(
        {
            alignItems: "center",
            borderRadius: BorderRadiuses.borderLarge,
            overflow: "hidden",
            position: "relative",
        },
        buildStylesByBreakpoint("padding", ColumnGaps),
        buildStylesByBreakpoint("rowGap", ColumnGaps),
    );

    const osBannerStyles = css({ position: "relative", zIndex: 5 });

    const backgroundMediaStyles = css({
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
    });

    /**
     * Rendering
     */
    const renderProductDemo = () => {
        return (
            <GridColumn
                className={css({
                    zIndex: 10,
                })}
                columnSpan={{
                    extraSmall: "full",
                    medium: 10,
                    large: 8,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 2,
                    large: 3,
                }}
                ref={demoContainerRef}
            >
                <RiveAnimation
                    aspectRatio="16 / 9"
                    priority={priority}
                    src={props.src}
                    stateMachineName="State Machine 1"
                />
            </GridColumn>
        );
    };

    return (
        <SubgridContainer className={demoContainerStyles} ref={containerRef}>
            {renderProductDemo()}

            <GridColumn className={osBannerStyles}>
                <OperatingSystemBanner />
            </GridColumn>

            <Picture
                {...props.backgroundMedia}
                aspectRatio="stretch"
                className={backgroundMediaStyles}
                priority={priority}
                quality={70}
                shouldParallax={true}
                sizeConfiguration="fullWidth"
            />
        </SubgridContainer>
    );
};
